var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-between mb-4"},[(!_vm.isSelectedCompanyBranch)?_c('b-button',{staticClass:"mb-4",attrs:{"variant":"primary"},on:{"click":_vm.redirectToCreateNewCompany}},[_vm._v(" "+_vm._s(_vm.$t("MENU.NEW"))+" "+_vm._s(_vm.subTitle)+" ")]):_vm._e()],1),_c('DatePickers',{attrs:{"filters":_vm.filters},on:{"option-selected":_vm.onOptionSelected,"on-start-date-selected":_vm.onStartDateSelected,"on-end-date-selected":_vm.onEndDateSelected,"on-all-dates-selected":_vm.onAllDatesSelected}}),_c('ListSearch',{attrs:{"search-name":_vm.subTitle},on:{"filter-change":_vm.onFilterChange}}),_c('b-table',{ref:"listTable",attrs:{"id":"list-table","primary-key":"_id","items":_vm.fetchData,"fields":_vm.fields,"filter":_vm.filter,"filter-included-fields":_vm.filterIncludedFields,"selectable":"","select-mode":"single","busy":_vm.isBusy,"per-page":_vm.perPage,"current-page":_vm.currentPage,"stacked":"md"},on:{"update:busy":function($event){_vm.isBusy=$event},"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-5"},[_c('b-spinner',{staticClass:"align-middle busy-spinner"})],1)]},proxy:true},{key:"cell(representativeName)",fn:function(row){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(row.item.representativeName))]),_c('button',{staticClass:"list-button",class:row.detailsShowing && row.item.rowDetails === 'representative'
                ? 'rotate-down'
                : '',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.fetchSubData(row, 'representative')}}},[_c('b-icon',{attrs:{"icon":"chevron-up","variant":"success","font-scale":"1.5"}})],1)])]}},{key:"cell(isActive)",fn:function(data){return [(data.value)?_c('b-icon',{staticClass:"common-icon-color",attrs:{"icon":"check","font-scale":"2.5","variant":"success"}}):_c('b-icon',{staticClass:"common-icon-color",attrs:{"icon":"x","font-scale":"2.5"}})]}},{key:"cell(buttons)",fn:function(row){return [_c('button',{staticClass:"list-button",class:row.detailsShowing && row.item.rowDetails === 'totals'
              ? 'rotate-down'
              : '',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.fetchSubData(row, 'totals')}}},[_c('b-icon',{attrs:{"icon":"currency-exchange","variant":"success","font-scale":"1.5"}})],1),(row.item.companyRank !== 'DISTRIBUTOR')?_c('button',{staticClass:"list-button",on:{"click":function($event){return _vm.navigateToMcmWallet(row.item)}}},[_c('i',{staticClass:"la la-wallet text-info",staticStyle:{"scale":"1.3"}})]):_vm._e(),(row.item.companyRank !== 'BRANCH')?_c('button',{staticClass:"list-button",class:row.detailsShowing && row.item.rowDetails === 'companies'
              ? 'rotate-down'
              : '',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.fetchSubData(row, 'companies')}}},[_c('b-icon',{attrs:{"icon":"list-ul","variant":"info","font-scale":"1.5"}})],1):_vm._e()]}},{key:"row-details",fn:function(row){return [_c('b-card',{staticClass:"mx-4 px-4 mb-5",attrs:{"no-body":""}},[_c('b-table',{attrs:{"caption-top":"","selectable":"","primary-key":"id","items":row.item.subDetails.subData,"fields":row.item.subDetails.fields,"stacked":"md"},on:{"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"table-caption",fn:function(){return [_vm._v(" "+_vm._s(row.item.subDetails.caption)+" ")]},proxy:true},{key:"cell(isActive)",fn:function(data){return [(data.value)?_c('b-icon',{staticClass:"common-icon-color",attrs:{"icon":"check","font-scale":"2.5","variant":"success"}}):_c('b-icon',{staticClass:"common-icon-color",attrs:{"icon":"x","font-scale":"2.5"}})]}},{key:"cell(buttonsCompany)",fn:function(row){return [(row.item.companyRank !== 'DISTRIBUTOR')?_c('button',{staticClass:"list-button",on:{"click":function($event){return _vm.navigateToMcmWallet(row.item)}}},[_c('i',{staticClass:"la la-wallet text-info",staticStyle:{"scale":"1.3"}})]):_vm._e()]}}],null,true)})],1)]}}])}),_c('div',{staticClass:"mt-5 col-12 pt-5"},[_c('b-pagination',{attrs:{"align":"fill","total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"orders-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }