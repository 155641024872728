<template>
  <company-list :fixedFilters="fixedFilters" />
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import CompanyList from "@/components/list/CompanyList.vue";
import { mapGetters } from "vuex";

export default {
  components: { CompanyList },
  computed: {
    ...mapGetters(["activeUser"])
  },
  data() {
    return {
      fixedFilters: "parent.id=1"
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t("ROUTES.COMPANIES") }
    ]);

    this.$store.dispatch("company/CLEAR_SELECTED_COMPANY");
    if (this.activeUser?.id) {
      this.fixedFilters = `${this.fixedFilters}&responsibleAdminId=${this.activeUser.id}`;
    }
  }
};
</script>
