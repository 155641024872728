<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between mb-4">
        <b-button
          v-if="!isSelectedCompanyBranch"
          class="mb-4"
          variant="primary"
          @click="redirectToCreateNewCompany"
        >
          {{ $t("MENU.NEW") }}
          {{ subTitle }}
        </b-button>
      </div>

      <DatePickers
        :filters="filters"
        @option-selected="onOptionSelected"
        @on-start-date-selected="onStartDateSelected"
        @on-end-date-selected="onEndDateSelected"
        @on-all-dates-selected="onAllDatesSelected"
      />

      <ListSearch @filter-change="onFilterChange" :search-name="subTitle" />

      <b-table
        ref="listTable"
        id="list-table"
        primary-key="_id"
        :items="fetchData"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterIncludedFields"
        selectable
        select-mode="single"
        :busy.sync="isBusy"
        :per-page="perPage"
        :current-page="currentPage"
        @row-clicked="onRowClicked"
        stacked="md"
      >
        <template #table-busy>
          <div class="text-center text-danger my-5">
            <b-spinner class="align-middle busy-spinner"></b-spinner>
          </div>
        </template>

        <template #cell(representativeName)="row">
          <div class="d-flex justify-content-between">
            <span>{{ row.item.representativeName }}</span>
            <button
              class="list-button"
              :class="
                row.detailsShowing && row.item.rowDetails === 'representative'
                  ? 'rotate-down'
                  : ''
              "
              @click.stop.prevent="fetchSubData(row, 'representative')"
            >
              <b-icon
                icon="chevron-up"
                variant="success"
                font-scale="1.5"
              ></b-icon>
            </button>
          </div>
        </template>

        <template #cell(isActive)="data">
          <b-icon
            icon="check"
            font-scale="2.5"
            class="common-icon-color"
            variant="success"
            v-if="data.value"
          ></b-icon>
          <b-icon
            icon="x"
            v-else
            font-scale="2.5"
            class="common-icon-color"
          ></b-icon>
        </template>

        <template #cell(buttons)="row">
          <button
            class="list-button"
            :class="
              row.detailsShowing && row.item.rowDetails === 'totals'
                ? 'rotate-down'
                : ''
            "
            @click.stop.prevent="fetchSubData(row, 'totals')"
          >
            <b-icon
              icon="currency-exchange"
              variant="success"
              font-scale="1.5"
            ></b-icon>
          </button>

          <button
            class="list-button"
            @click="navigateToMcmWallet(row.item)"
            v-if="row.item.companyRank !== 'DISTRIBUTOR'"
          >
            <i class="la la-wallet text-info" style="scale: 1.3;"></i>
          </button>

          <button
            v-if="row.item.companyRank !== 'BRANCH'"
            class="list-button"
            :class="
              row.detailsShowing && row.item.rowDetails === 'companies'
                ? 'rotate-down'
                : ''
            "
            @click.stop.prevent="fetchSubData(row, 'companies')"
          >
            <b-icon icon="list-ul" variant="info" font-scale="1.5"></b-icon>
          </button>
        </template>

        <template #row-details="row">
          <b-card class="mx-4 px-4 mb-5" no-body>
            <b-table
              caption-top
              selectable
              primary-key="id"
              :items="row.item.subDetails.subData"
              :fields="row.item.subDetails.fields"
              @row-clicked="onRowClicked"
              stacked="md"
            >
              <template #table-caption>
                {{ row.item.subDetails.caption }}
              </template>
              <template #cell(isActive)="data">
                <b-icon
                  icon="check"
                  font-scale="2.5"
                  class="common-icon-color"
                  variant="success"
                  v-if="data.value"
                ></b-icon>
                <b-icon
                  icon="x"
                  v-else
                  font-scale="2.5"
                  class="common-icon-color"
                ></b-icon>
              </template>
              <template #cell(buttonsCompany)="row">
                <button
                  class="list-button"
                  @click="navigateToMcmWallet(row.item)"
                  v-if="row.item.companyRank !== 'DISTRIBUTOR'"
                >
                  <i class="la la-wallet text-info" style="scale: 1.3;"></i>
                </button>
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>

      <div class="mt-5 col-12 pt-5">
        <b-pagination
          align="fill"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="orders-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ListSearch from "@/components/list/ListSearch.vue";
import DatePickers from "@/components/list/DatePickers.vue";
import { ListMixin } from "@/mixins/list.mixin";
import { getChildRole } from "@/constants/roles";

export default {
  components: { ListSearch, DatePickers },
  mixins: [ListMixin],
  props: ["fixedFilters"],
  data() {
    return {
      filterIncludedFields: ["title"],
      filters: [
        {
          text: "COMMON.ACCESS",
          field: "isActive",
          currentValue: undefined
        }
      ],
      fields: [
        {
          key: "_id",
          label: "ID",
          sortable: true
        },
        {
          key: "title",
          label: this.$t("COMPANY.TITLE"),
          sortable: true
        },
        /* {
          key: "representativeName",
          label: this.$t("COMPANY.SALES_REPRESENTATIVE"),
          sortable: true
        }, */
        {
          key: "companyRank",
          label: this.$t("LIST.LEVEL"),
          sortable: true
        },
        {
          key: "isActive",
          label: this.$t("COMMON.ACCESS"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "createdAt",
          label: this.$t("LIST.CREATED_AT"),
          sortable: true,
          class: "text-center",
          formatter: value => {
            return this.$dateTimeFilter(value);
          }
        },
        {
          key: "buttons",
          label: "",
          class: "text-center"
        }
      ],
      totalFields: [
        {
          key: "name",
          label: this.$t("FORM.PAYMENT_UNIT"),
          sortable: true
        },
        {
          key: "ordersCount",
          label: this.$t("PAGES.SALES_MANAGEMENT.LINK_SALES.QUANTITY"),
          sortable: true
        },
        {
          key: "orderTotal",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.TOTAL"),
          sortable: true,
          class: "text-right"
        }
      ],
      companyFields: [
        {
          key: "id",
          label: "ID",
          sortable: true
        },
        {
          key: "title",
          label: this.$t("COMPANY.TITLE"),
          sortable: true
        },
        {
          key: "email",
          label: this.$t("FORM.EMAIL"),
          sortable: true
        },
        /* {
          key: "representativeName",
          label: this.$t("COMPANY.SALES_REPRESENTATIVE"),
          sortable: true
        }, */
        {
          key: "isActive",
          label: this.$t("COMMON.ACCESS"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "createdAt",
          label: this.$t("LIST.CREATED_AT"),
          sortable: true,
          class: "text-center",
          formatter: value => {
            return this.$dateTimeFilter(value, "date-time");
          }
        },
        {
          key: "buttonsCompany",
          label: "",
          class: "text-center"
        }
      ],
      representativeFields: [
        {
          key: "id",
          label: "ID"
        },
        {
          key: "name",
          label: this.$t("FORM.FULL_NAME")
        },
        {
          key: "email",
          label: this.$t("FORM.EMAIL")
        },
        { key: "phone", label: this.$t("FORM.PHONE") }
      ]
    };
  },
  computed: {
    fetchUrl() {
      return "companies";
    },
    ...mapGetters({
      selectedCompany: "company/SELECTED_COMPANY",
      selectedCompanyId: "company/SELECTED_COMPANY_ID",
      selectedCompanyRole: "company/SELECTED_COMPANY_ROLE",
      selectedCompanyRoleId: "company/SELECTED_COMPANY_ROLE_ID",
      isSelectedCompanyBranch: "company/IS_SELECTED_COMPANY_BRANCH",
      isSelectedCompanyDist: "company/IS_SELECTED_COMPANY_DIST",
      isSelectedCompanyMerch: "company/IS_SELECTED_COMPANY_MERCHANT"
    }),
    subTitle() {
      const child = getChildRole(this.selectedCompanyRoleId);
      const title = this.$t(`PAGES.SALES_MANAGEMENT.LIST.LEVELS["${child}"]`);
      return title;
    },
    parentId() {
      return this.selectedCompanyId ?? 1;
    },
    parentCompany() {
      return this.selectedCompany ?? 1;
    },
    showMcmWalletIcon() {
      return this.selectedCompanyRole !== "GENESIS";
    }
  },
  watch: {
    selectedCompany() {
      this.listTable.refresh();
    }
  },
  methods: {
    ...mapActions({
      setSelecterepresentative: "representative/SET_SELECTED_REPRESENTATIVE",
      clearSelectedrepresentative:
        "representative/CLEAR_SELECTED_REPRESENTATIVE",
      setSelectedCompany: "company/SET_SELECTED_COMPANY",
      clearSelectedCompany: "company/CLEAR_SELECTED_COMPANY",
      setParentCompany: "company/SET_PARENT_COMPANY"
    }),
    async fetchSubData(row, subDataType) {
      const detailsShowing = !row.detailsShowing;
      const item = row.item;

      let caption = null;
      let fields = null;
      let action = null;

      switch (subDataType) {
        case "totals":
          caption = "PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_TOTAL";
          fields = this.totalFields;
          action = "orders/GET_ORDERS_TOTAL";
          break;
        case "companies":
          caption = "PAGES.SALES_MANAGEMENT.LIST.AFFILIATED_COMPANIES";
          fields = this.companyFields;
          action = "company/GET_SUB_COMPANIES";
          break;
        case "representative":
          caption = "COMPANY.SALES_REPRESENTATIVE";
          fields = this.representativeFields;
          action = "company/GET_REPRESENTATIVE";
          break;
      }

      item["rowDetails"] = subDataType;
      item["subDetails"] = {
        caption: `${item.title} ${this.$t(caption)}`,
        fields: fields
      };

      await this.fetchOrdersTotals(detailsShowing, item, action);

      row.toggleDetails();
    },
    async fetchOrdersTotals(detailsShowing, item, action) {
      if (detailsShowing) {
        const subData = await this.$store.dispatch(action, item._id);
        item.subDetails.subData = subData;
      }
    },
    async onRowClicked(item) {
      if (!item) {
        this.clearSelectedCompany();
        this.clearSelectedrepresentative();
        return;
      }

      this.setParentCompany(this.parentCompany);

      const isCompany = item.title;
      const route = isCompany ? "company" : "representative";

      const action = isCompany
        ? this.setSelectedCompany
        : this.setSelecterepresentative;

      action(item).then(() => {
        this.redirect(false, route);
      });
    },
    redirect(isNew, route) {
      const isRoot =
        this.$router.currentRoute.fullPath === "/companies/company-list";

      if (isRoot) {
        this.$router.push({
          name: route,
          params: { parentId: this.parentId, isNew: isNew }
        });
      } else {
        if (isNew) {
          this.$emit("new-company");
        }
      }
    },
    prepareDeletedLink() {
      this.$router.push({ name: "company-companies-deleted" });
    },
    navigateToMcmWallet(item) {
      const companyId = item?._id ? item._id : item.id;
      // console.log(companyId);
      this.$setObject("wallet_company", {
        role: item.companyRank,
        name: item.title
      });
      this.$router.push({
        name: "company-mcm-wallet",
        params: { companyId }
      });
    },
    redirectToCreateNewCompany() {
      this.$router.push({
        name: "new-company"
      });
      /* if (
        this.selectedCompany &&
        Object.keys(this.selectedCompany).length === 0
      ) {
        console.log("redirect to create new dist");
        return;
      }
      if (this.isSelectedCompanyDist) {
        console.log("redirect to create new merch");
      }
      if (this.isSelectedCompanyMerch) {
        console.log("redirect to create new branch");
      } */
    }
  }
};
</script>

<style lang="scss" scoped>
.table td {
  padding: 0.5rem;
  vertical-align: middle;
}
</style>
